import React from "react";
import AdvanceTool from "components/AIArtWork/AdvanceTool";
import { Container } from "styles/components/AIArtwork";

const ArtWork = ({
  orderLine = {},
  onDrop,
  setCoordinates,
  onPreview,
  setOrderLine,
  onFabricDrop,
  onFabricRemove,
  product,
  plus,
  dtgPlusProcess,
  printType,
  colors,
  state,
  setState,
}) => {
  const advanceTool = () => (
    <AdvanceTool
      printType={printType}
      orderLine={{ ...orderLine, aiGenerateImages: true }}
      onFabricDrop={onFabricDrop}
      setCoordinates={setCoordinates}
      onFabricRemove={onFabricRemove}
      onPreview={onPreview}
      setOrderLine={setOrderLine}
      product={product}
      plus={plus}
      dtgPlusProcess={dtgPlusProcess}
      onDrop={onDrop}
      isAiGeneratedMockUps={true}
      colors={colors}
      state={state}
      setState={setState}
    />
  );

  return <Container>{advanceTool()}</Container>;
};

export default ArtWork;
