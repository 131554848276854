import { post, get } from "@axios";
import { showLoader, hideLoader } from "actions/loader";
import { requests } from "shared/requests";
import { AUTH_LOGOUT } from "actions/auth";
import { INITIAL_SYNC_PRODUCTS } from "actions/sync-products";
import { INITIATE_STORES } from "actions/stores";

const getGeneratedImages = (token) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const orders = await get({
        url: requests?.generateImage,
        token,
      });
      dispatch(hideLoader());

      if (orders?.token === "failed") {
        dispatch({ type: INITIATE_STORES });
        dispatch({ type: AUTH_LOGOUT });
        dispatch({ type: INITIAL_SYNC_PRODUCTS });
      }

      return orders;
    } catch (error) {
      dispatch(hideLoader());
      return { error: true, message: error.message };
    }
  };
};

const checkImageForBrand = (data, token) => {
  return async (dispatch) => {
    try {
      dispatch(showLoader());
      const images = await post({
        url: requests.checkImageForBrand,
        data,
        token,
      });

      dispatch(hideLoader());

      if (images?.token === "failed") {
        dispatch({ type: INITIATE_STORES });
        dispatch({ type: AUTH_LOGOUT });
        dispatch({ type: INITIAL_SYNC_PRODUCTS });
      }

      return images;
    } catch (error) {
      dispatch(hideLoader());
      return { error: true, message: error.error };
    }
  };
};

const checkAIDescriptionForBrand = (data, token) => {
  return async (dispatch) => {
    try {
      dispatch(showLoader());
      const images = await post({
        url: requests.checkAIDescriptionForBrand,
        data,
        token,
      });
      dispatch(hideLoader());

      if (images?.token === "failed") {
        dispatch({ type: INITIATE_STORES });
        dispatch({ type: AUTH_LOGOUT });
        dispatch({ type: INITIAL_SYNC_PRODUCTS });
      }

      return images;
    } catch (error) {
      dispatch(hideLoader());
      return { error: true, message: error.error };
    }
  };
};

const generateAIImages = (data, token) => {
  return async (dispatch) => {
    try {
      const images = await post({
        url: requests.generateImage,
        data,
        token,
      });

      if (images?.token === "failed") {
        dispatch({ type: INITIATE_STORES });
        dispatch({ type: AUTH_LOGOUT });
        dispatch({ type: INITIAL_SYNC_PRODUCTS });
      }

      return images;
    } catch (error) {
      return { error: true, message: error.error };
    }
  };
};

const generateLogoImage = (data, token) => {
  return async (dispatch) => {
    dispatch(showLoader());
    try {
      const images = await post({
        url: requests.generateLogoImage,
        data,
        token,
      });
      dispatch(hideLoader());

      if (images?.token === "failed") {
        dispatch({ type: INITIATE_STORES });
        dispatch({ type: AUTH_LOGOUT });
        dispatch({ type: INITIAL_SYNC_PRODUCTS });
      }

      return images;
    } catch (error) {
      dispatch(hideLoader());
      return { error: true, message: error.error };
    }
  };
};

export {
  getGeneratedImages,
  generateAIImages,
  checkAIDescriptionForBrand,
  checkImageForBrand,
  generateLogoImage,
};
