import React from "react";
import _ from "lodash";
import Dialog from "@material-ui/core/Dialog";
import Button from "components/Button";
import Stepper from "components/Stepper";
import CreateBasicOrder from "pages/orders/CreateBasicOrder";
import ShippingInformation from "pages/orders/ShippingInformation";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useHistory } from "react-router-dom";
import CreateOrderModalImage from "assets/images/create-order-modal.png";
import {
  Heading,
  Container,
  BasicOrder,
  CreateOrderButton,
  ImageWrapper,
  BulkOrder,
} from "styles/pages/orders/createOrder";

const CreateOrder = ({
  open,
  show,
  hide,
  handleNext,
  handleBack,
  activeStep,
  save,
}) => {
  const history = useHistory();

  return (
    <CreateOrderButton>
      <Button
        title="Create Order"
        onClick={() => _.isFunction(show) && show()}
        style={{
          background: "#000",
          boxShadow: "none",
          height: "48px",
          textTransform: "capitalize",
          color: "#1594db",
          fontFamily: "Inter500",
          fontSize: "16px",
          fontWeight: "bold",
        }}
      />

      <Dialog
        maxWidth="md"
        open={open}
        onClose={() => _.isFunction(hide) && hide()}
      >
        <Container>
          {activeStep > 0 && (
            <Button
              containerStyle={{
                backgroundColor: "#F9682A",
                fontSize: "12px",
                borderRadius: "10px",
                padding: "3px 8px",
                lineHeight: "1.5",
                color: "#fff",
                border: "1px solid #e7eaec",
                textTransform: "capitalize",
                fontFamily: "Inter600",
                height: "23px",
              }}
              variant="text"
              title="Back"
              onClick={() => _.isFunction(handleBack) && handleBack()}
            />
          )}

          <Stepper
            activeStep={activeStep}
            steps={[
              "Choose Order Type",
              "Add Shipping Information",
              "Add Products",
            ]}
          />

          {activeStep === 0 && (
            <React.Fragment>
              <BulkOrder>
                Bulk discounts for orders containing multiple items shipped to
                the same address.
              </BulkOrder>
              <ImageWrapper>
                <img src={CreateOrderModalImage} alt="CreateOrderModal" />
              </ImageWrapper>
              <Heading>
                <p>
                  Direct To Garment Decoration Fulfillment times: 3 – 7 business
                  days.
                </p>
                <p>
                  DTG Plus Decoration Fulfillment times: 5 – 10 business days.
                </p>
                <p>
                  TUFF PRINT TRANSFER Decoration Fulfillment times: 5 – 10
                  business days.
                </p>
                <p>
                  Durasoft Decoration Fulfillment times: 7 – 10 business days.
                </p>
                <p>
                  Embroidery Decoration Fulfillment times: 7– 10 business days.
                </p>
                <p>
                  All Over Print Decoration Fulfillment times: 14 – 24 business
                  days.
                </p>
                <p>
                  Sublimation Decoration Fulfillment times: 7 – 10 business
                  days.
                </p>
              </Heading>
              <BasicOrder>
                <CreateBasicOrder handleNext={handleNext} />
              </BasicOrder>
            </React.Fragment>
          )}

          {activeStep === 1 && (
            <ShippingInformation
              save={(payload) => _.isFunction(save) && save(payload)}
            />
          )}
        </Container>
      </Dialog>
    </CreateOrderButton>
  );
};

export default CreateOrder;
