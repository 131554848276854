import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { getDrawerItems } from "components/Drawer/data";
import Chip from "@material-ui/core/Chip";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import InfoIcon from "@material-ui/icons/Info";
import AboutDialog from "components/Drawer/AboutModal";
import {
  Logo,
  ItemText,
  Blinking,
  LogoWrapper,
  DrawerContainer,
} from "styles/components/Drawer";

const useStyles = makeStyles({
  root: {
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
    },
  },
  wrapper: {
    width: 320,
    backgroundColor: "#000",
    borderRadius: "15px",
    margin: "0 15px 15px",
    padding: "15px 0",
  },

  items: {
    height: "51px",
    padding: "14px 20px 14px 23px",
    width: "calc(100% - 20px)",
    margin: "3px auto",
    borderRadius: "25px",

    "&:hover": {
      backgroundColor: "#fff",
      color: "#fff",
    },
  },

  icon: {
    minWidth: "fit-content",
    marginRight: "6px",
  },
  listText: {
    textDecoration: "none",
  },
  innerList: {
    height: "32px",
    padding: "0 15px 0 32px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
      color: "rgb(91, 146, 230)",
    },
  },
  link: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
  },
});

const CustomDrawer = ({ open, toggle }) => {
  const [collapseVisibility, updateCollapseVisibility] = useState({});
  const [aboutModalOpen, setAboutModalOpen] = useState(false);
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const classes = useStyles();
  const drawerItems = getDrawerItems(emailid);

  const homeWebPage = useSelector((state) => state?.homeWebPage);
  const homeData = homeWebPage?.find((p) => p?.name === "Header") || {};
  const { components } = homeData;
  const { Image } = components || {};

  const CustomListItem = ({ item, isChild }) => (
    <ListItem
      className={
        item.image ? null : isChild ? classes.innerList : classes.items
      }
      button
      onClick={() => {
        if (item.route) {
          toggle();
          return;
        }
        updateCollapseVisibility({
          ...collapseVisibility,
          [item.title]: !collapseVisibility[item.title],
        });
      }}
    >
      <ListItemIcon className={classes.icon}>{item.icon}</ListItemIcon>
      {item.image ? (
        item.image
      ) : (
        <ItemText>
          {item.title}
          {item?.inventory && (
            <>
              &nbsp;&nbsp;[&nbsp;
              <Blinking>
                <circle cx="5" cy="5" r="5" fill="red" />
              </Blinking>
              &nbsp;&nbsp;LIVE&nbsp;]
            </>
          )}
        </ItemText>
      )}
      {item?.newRelease && (
        <Chip
          icon={<NewReleasesIcon style={{ color: "#fff" }} />}
          label="New"
          style={{ color: "#fff", background: "#ff3c3c", marginLeft: 10 }}
          size="small"
        />
      )}
    </ListItem>
  );

  return (
    <Drawer anchor="left" open={open} onClose={toggle} className={classes.root}>
      <DrawerContainer>
        <LogoWrapper>
          <Logo src={Image} alt="DrawerLogo" />
        </LogoWrapper>
        <List className={classes.wrapper}>
          {drawerItems.map((item, index) =>
            item.route ? (
              <NavLink
                to={item.route}
                key={`${item.title}_link_${index}`}
                className={classes.link}
              >
                <CustomListItem item={item} />
              </NavLink>
            ) : (
              <Fragment key={`${item.title}_${index}`}>
                <CustomListItem item={item} />
                <Collapse in={collapseVisibility[item.title]}>
                  {item.childItems.map((childItem, childIndex) =>
                    childItem.route ? (
                      <NavLink
                        to={childItem.route}
                        className={classes.link}
                        key={`${item.title}_childLink_${childIndex}`}
                      >
                        <CustomListItem
                          isChild={true}
                          item={childItem}
                          key={`${item.title}_childLinkItem_${childIndex}`}
                        />
                      </NavLink>
                    ) : childItem.externalRoute ? (
                      <a
                        href={childItem.externalRoute}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.link}
                        key={`${item.title}_childLink_${childIndex}`}
                      >
                        {childItem.image ? (
                          childItem.image
                        ) : (
                          <CustomListItem
                            isChild={true}
                            item={childItem}
                            key={`${item.title}_childLinkItem_${childIndex}`}
                          />
                        )}
                      </a>
                    ) : (
                      <CustomListItem
                        item={childItem}
                        key={`${item.title}_childItem_${childIndex}`}
                      />
                    )
                  )}
                </Collapse>
              </Fragment>
            )
          )}
          <a className={classes.link}>
            <ListItem
              className={classes.items}
              button
              onClick={() => setAboutModalOpen(!aboutModalOpen)}
            >
              <ListItemIcon className={classes.icon}>
                <InfoIcon />
              </ListItemIcon>
              <ItemText>About Us</ItemText>
            </ListItem>
          </a>
        </List>
        <AboutDialog
          open={aboutModalOpen}
          setOpen={(v) => setAboutModalOpen(v)}
        />
      </DrawerContainer>
    </Drawer>
  );
};

CustomDrawer.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
};

export default CustomDrawer;
