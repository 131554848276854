import React from "react";
import {
  Dashboard as DashboardIcon,
  Store as StoreIcon,
  MenuBook as MenuBookIcon,
} from "@material-ui/icons";
import SettingsIcon from "@material-ui/icons/Settings";
import CenterFocusWeakIcon from "@material-ui/icons/CenterFocusWeak";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Fireplace from "@material-ui/icons/Fireplace";
import BallotIcon from "@material-ui/icons/Ballot";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import BagIcon from "assets/svg/BagIcon";
import { ROUTES } from "shared/constants/routes";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import ShopifyLogo from "assets/images/shopify.png";
import EtsyLogo from "assets/images/etsy.png";
import WooCommerceLogo from "assets/images/woo-commerce.png";
import BigCommerceLogo from "assets/images/big-commerce-lg-logo.png";
import BigCartelLogo from "assets/images/big-cartel-logo.png";

const getDrawerCommonItems = (emailid) => {
  return [
    {
      title: "Dashboard",
      icon: <DashboardIcon />,
      route: ROUTES.DASHBOARD.LINK,
    },
    {
      title: "E-commerce connections",
      icon: <StoreIcon />,
      childItems: [
        {
          title: "Connect to Etsy",
          externalRoute: `https://www.etsy.com/oauth/connect?response_type=code&client_id=3qsz4oisydriloew95xo5z28&redirect_uri=https://shirtly-app.herokuapp.com/etsyRedirect&scope=address_r%20billing_r%20cart_r%20email_r%20listings_r%20profile_r%20recommend_r%20shops_r%20transactions_r&state=${emailid}&code_challenge=DSWlW2Abh-cf8CeLL8-g3hQ2WQyYdKyiu83u_s7nRhI&code_challenge_method=S256`,
          image: (
            <img
              src={EtsyLogo}
              alt="s"
              style={{ width: "30%", marginLeft: "20px" }}
            />
          ),
        },
        {
          title: "Connect to Shopify",
          externalRoute: "https://apps.shopify.com/shirtly-fulfillment",
          image: (
            <img
              src={ShopifyLogo}
              alt="s"
              style={{ width: "30%", marginLeft: "30px" }}
            />
          ),
        },
        {
          title: "Connect to BigCommerce",
          externalRoute: "https://www.bigcommerce.com/apps/shirtly/",
          image: (
            <img
              src={BigCommerceLogo}
              alt="s"
              style={{ width: "30%", marginLeft: "30px" }}
            />
          ),
        },
        {
          title: "Connect to WooCommerce",
          route: ROUTES.CONNECT_TO_WOO_COMMERCE.LINK,
          image: (
            <img
              src={WooCommerceLogo}
              alt="s"
              style={{ width: "50%", marginLeft: "4px" }}
            />
          ),
        },

        {
          title: "Connect to BigCartel",
          externalRoute: `https://my.bigcartel.com/oauth/authorize?client_id=7JHOWyyFTLGGlSApqk6OSZJxtaH570&response_type=code&state=${emailid}&redirect_uri=https://shirtly-app.herokuapp.com/bigCartelRedirect`,
          image: (
            <img
              src={BigCartelLogo}
              alt="s"
              style={{ width: "30%", marginLeft: "30px" }}
            />
          ),
        },
      ],
    },

    {
      title: ROUTES.MY_STORES.TITLE,
      route: ROUTES.MY_STORES.LINK,
      icon: <StoreIcon />,
    },
    {
      title: ROUTES.MY_STORES_SYNC_PRODUCTS.TITLE,
      route: ROUTES.MY_STORES_SYNC_PRODUCTS.LINK,
      icon: <MenuBookIcon />,
    },
    {
      title: "Orders",
      icon: <BagIcon color="inherit" />,
      route: ROUTES.ORDERS.LINK,
    },
    {
      title: "Product & Pricing",
      icon: <MenuBookIcon />,
      route: ROUTES.CATALOG.LINK,
    },
    {
      title: "Mock-Up Generator",
      icon: <CenterFocusWeakIcon />,
      route: ROUTES.MY_STORES_PRODUCTS.LINK,
      newRelease: true,
    },

    {
      title: ROUTES.BACKGROUND_BURNER.TITLE,
      icon: <Fireplace />,
      route: ROUTES.BACKGROUND_BURNER.LINK,
      newRelease: true,
    },
    {
      icon: <AssessmentIcon />,
      title: ROUTES.USER_SALES_REPORT.TITLE,
      route: ROUTES.USER_SALES_REPORT.LINK,
    },
    {
      icon: <BallotIcon />,
      title: ROUTES.INVENTORY.TITLE,
      route: ROUTES.INVENTORY.LINK,
      inventory: true,
    },
    {
      title: ROUTES.VERIFY_ART_FILES.TITLE,
      route: ROUTES.VERIFY_ART_FILES.LINK,
      icon: <DoneOutlineIcon />,
      newRelease: true,
    },
    {
      title: "Account Settings",
      icon: <SettingsIcon />,
      route: ROUTES.ACCOUNT_SETTINGS.LINK,
    },
  ];
};

const getDrawerItems = (emailId) => {
  const isAdmin = emailId === "support@shirtly.com";

  const tempAdmin =
    emailId === "mlamothe@docpartners.ca" || emailId === "west@gowest.ai";

  if (isAdmin) {
    return [
      {
        title: "Admin",
        icon: <SupervisorAccountIcon />,
        childItems: [
          {
            title: ROUTES.WEB_PAGES.TITLE,
            route: ROUTES.WEB_PAGES.LINK,
          },
          {
            title: "Users",
            route: ROUTES.ADMIN_USERS.LINK,
          },
          {
            title: ROUTES.ADMIN_IP_PROGRAM.TITLE,
            route: ROUTES.ADMIN_IP_PROGRAM.LINK,
          },
          {
            title: "All Orders",
            route: ROUTES.WAREHOUSE.LINK,
          },

          {
            title: "Settings",
            route: ROUTES.ADMIN_SETTINGS.LINK,
          },
          {
            title: ROUTES.ADMIN_DTG_PLUS.TITLE,
            route: ROUTES.ADMIN_DTG_PLUS.LINK,
          },
          {
            title: ROUTES.ADMIN_SCREEN_PRINTING.TITLE,
            route: ROUTES.ADMIN_SCREEN_PRINTING.LINK,
          },
          {
            title: "Products",
            route: ROUTES.ADMIN_EDIT_PRODUCT.LINK,
          },
          {
            title: ROUTES.ADMIN_SALES_REPORT.TITLE,
            route: ROUTES.ADMIN_SALES_REPORT.LINK,
          },
          {
            title: ROUTES.ORDER_STOCK.TITLE,
            route: ROUTES.ORDER_STOCK.LINK,
          },
          {
            title: ROUTES.SUBMITTED_ORDER_STOCK.TITLE,
            route: ROUTES.SUBMITTED_ORDER_STOCK.LINK,
          },
          {
            title: ROUTES.FULFILLMENT_DAYS.TITLE,
            route: ROUTES.FULFILLMENT_DAYS.LINK,
          },
          {
            title: ROUTES.NOTIFICATIONS.TITLE,
            route: ROUTES.NOTIFICATIONS.LINK,
          },
        ],
      },

      {
        title: ROUTES.AI_MOCK_UP_GENERATOR.TITLE,
        route: ROUTES.AI_MOCK_UP_GENERATOR.LINK,
        newRelease: true,
      },

      ...getDrawerCommonItems(emailId),
    ];
  }

  if (tempAdmin) {
    return [
      {
        title: ROUTES.AI_MOCK_UP_GENERATOR.TITLE,
        route: ROUTES.AI_MOCK_UP_GENERATOR.LINK,
        newRelease: true,
      },

      ...getDrawerCommonItems(emailId),
    ];
  }

  return getDrawerCommonItems(emailId);
};

export { getDrawerItems };
