const ROUTES = {
  HOME: {
    TITLE: "Home",
    LINK: "/",
  },
  DASHBOARD: {
    TITLE: "Dashboard",
    LINK: "/dashboard",
  },
  ACCOUNT: {
    LINK: "/",
  },
  CATALOG: {
    LINK: "/catalog",
    TITLE: "Products Catalog",
  },
  ORDERS: {
    LINK: "/orders",
    TITLE: "Orders",
  },
  ORDER_DETAILS: {
    LINK: "/order-details",
  },
  MY_STORES: { LINK: "/my-stores", TITLE: "My Stores" },
  MY_STORES_PRODUCTS: {
    LINK: "/mock-up-generator",
    TITLE: "Mock Up Generator",
  },

  AI_MOCK_UP_GENERATOR: {
    LINK: "/ai-mock-up-generator",
    TITLE: "AI Mock Up Generator",
  },

  MY_STORES_SHIPPING: { LINK: "/my-stores/shipping" },
  MY_STORES_REGISTRATION: {
    LINK: "/my-stores/registration",
    TITLE: "Add Store",
  },
  CONNECT_TO_SHOPIFY: { LINK: "/connect-to-shopify" },
  CONNECT_TO_WOO_COMMERCE: { LINK: "/connect-to-woo-commerce" },
  BROWSE_CATALOG: { TITLE: "Browse", LINK: "/catalog/browse" },
  PRODUCT_DETAIL: { LINK: "/product-detail", TITLE: "Product Detail" },
  ACCOUNT_SETTINGS: { LINK: "/account-settings", TITLE: "Account Settings" },
  ADMIN_USERS: { LINK: "/admin/users" },
  ADMIN_SETTINGS: { LINK: "/admin/settings" },
  ADMIN_DTG_PLUS: { LINK: "/admin/dtg-plus", TITLE: "DTG Plus" },
  ADMIN_SCREEN_PRINTING: {
    LINK: "/admin/screen-printing",
    TITLE: "Screen Printing",
  },
  ADMIN_SALES_REPORT: { LINK: "/admin/sales-report", TITLE: "Sales Report" },
  USER_SALES_REPORT: { LINK: "/user/sales-report", TITLE: "Sales Report" },
  IP_PROGRAM: { LINK: "/ip-program", TITLE: "IP Program" },
  ADMIN_IP_PROGRAM: { LINK: "/admin/ip-program", TITLE: "IP Program" },
  ADMIN_CREATE_PRODUCT: { LINK: "/admin/create-product" },
  ADMIN_EDIT_PRODUCT: { LINK: "/admin/edit-product" },
  WAREHOUSE: { LINK: "/warehouse", TITLE: "Warehouse" },
  INVENTORY: { LINK: "/inventory", TITLE: "Inventory" },
  ORDER_STOCK: { LINK: "/order-stock", TITLE: "Order Stock" },
  SUBMITTED_ORDER_STOCK: {
    LINK: "/submitted-order-stock",
    TITLE: "Submitted Order Stock",
  },
  TERMS_AND_SERVICES: {
    LINK: "/terms-and-services-privacy-policy",
    TITLE: "Terms & Services / Privacy Policy",
  },
  HOW_TO_CONNECT_BIG_COMMERCE: {
    LINK: "/how-to-connect-big-commerce",
    TITLE: "How Do I Connect My BigCommerce Store To Shirtly ?",
  },
  SUPPORT: {
    LINK: "/support",
    TITLE: "Support",
  },
  FULFILLMENT_DAYS: {
    LINK: "/fulfillment-days",
    TITLE: "Fulfillment Days",
  },
  BACKGROUND_BURNER: {
    LINK: "/background-burner",
    TITLE: "Background Burner",
  },
  WEB_PAGES: {
    LINK: "/web-pages",
    TITLE: "Web Pages",
  },
  NOTIFICATIONS: {
    LINK: "/notifications",
    TITLE: "Notifications",
  },
  MY_STORES_SYNC_PRODUCTS: {
    LINK: "/sync-products",
    TITLE: "Sync Products",
  },
  MY_STORES_SYNC_PRODUCT: {
    LINK: "/sync-product",
    TITLE: "Sync Product",
  },
  VERIFY_ART_FILES: {
    LINK: "/verify-art-files",
    TITLE: "Verify Art Files",
  },
  ANNOUNCEMENTS: {
    LINK: "/announcements",
    TITLE: "Announcements",
  },
  NEWS_ROOM: {
    LINK: "/news-room",
    TITLE: "News Room",
  },
};

export { ROUTES };
