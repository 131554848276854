import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Button from "components/Button";
import { getDesigns } from "actions/designer";
import COLORS from "shared/constants/colors";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import BrowseLibraryTabs from "components/AIArtWork/NewBrowseLibraryTabs";
import { Container, Header } from "styles/components/ArtWork/browseLibrary";

const BrowseLibraryDrawer = ({
  onDrop,
  email,
  viewOnly = false,
  showAdvanceToolTab = false,
  printType,
  activeTabIndex,
}) => {
  const dispatch = useDispatch();

  const [libraryFiles, setLibraryFiles] = useState(undefined);
  const [bbLibraryFiles, setBBLibraryFiles] = useState(undefined);
  const [plusLibraryFiles, setPlusLibraryFiles] = useState(undefined);
  const [advancedLibraryFiles, setAdvancedLibraryFiles] = useState(undefined);
  const [advancedDesigns, setAdvancedDesigns] = useState(undefined);
  const [activeTab, setActiveTab] = useState(activeTabIndex || 0);
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);

  const populateLibraryData = (library) => {
    for (let i = 0; i < library?.resources?.length; i++) {
      const l = library?.resources[i];
      const secureUrl = l.secure_url;
      const splitSecureUrl = secureUrl.split("/");
      if (!!splitSecureUrl?.length) {
        l.name = splitSecureUrl[splitSecureUrl?.length - 1];
      }
    }
  };

  useEffect(() => {
    const loadLibrary = async () => {
      if (!libraryFiles) {
        const library = await dispatch(
          getDesigns({
            user: email || emailid,
          })
        );
        if (library.error) {
          return;
        }
        populateLibraryData(library);
        setLibraryFiles({
          ...library,
          cursors: library?.next_cursor
            ? [undefined, library?.next_cursor]
            : [],
        });
      }
    };
    loadLibrary();
  }, [dispatch, libraryFiles, setLibraryFiles, emailid, email]);

  return (
    <Container>
      <Header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <h2 style={{ margin: 0 }}>Design Library</h2>

        <Button
          title="Upload"
          containerStyle={{
            backgroundColor: COLORS.DARK_BLUE,
            color: COLORS.WHITE,
            fontWeight: "normal",
            textTransform: "none",
            borderRadius: "6px",
            fontSize: "16px",
            lineHeight: "1.5",
            boxShadow: "none",
          }}
          startIcon={<CloudUploadIcon />}
          onClick={() => {
            const myWidget = window.cloudinary.createUploadWidget(
              {
                cloudName: "big-oven-tees-inc",
                show_powered_by: false,
                uploadPreset: "tnedst8q",
                folder: `Shirtly/${emailid}/ArtFiles`,
                clientAllowedFormats: ["png", "pdf"],
                sources: ["local", "camera", "url"],
              },
              (error, result) => {
                if (!error && result && result.event === "success") {
                  setLibraryFiles(undefined);
                }
              }
            );
            myWidget.open();
          }}
        />
      </Header>

      <div>
        <BrowseLibraryTabs
          printType={printType}
          onDrop={onDrop}
          email={email}
          viewOnly={viewOnly}
          libraryFiles={libraryFiles}
          setLibraryFiles={setLibraryFiles}
          setActiveTab={setActiveTab}
          bbLibraryFiles={bbLibraryFiles}
          setBBLibraryFiles={setBBLibraryFiles}
          plusLibraryFiles={plusLibraryFiles}
          setPlusLibraryFiles={setPlusLibraryFiles}
          advancedLibraryFiles={advancedLibraryFiles}
          setAdvancedDesigns={setAdvancedDesigns}
          advancedDesigns={advancedDesigns}
          showAdvanceToolTab={showAdvanceToolTab}
          activeTabIndex={activeTabIndex}
          activeTab={activeTab}
        />
      </div>
    </Container>
  );
};

BrowseLibraryDrawer.propTypes = {
  open: PropTypes.bool,
};

export default BrowseLibraryDrawer;
