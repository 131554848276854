import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import Accordion from "@material-ui/core/Accordion";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Tooltip from "@material-ui/core/Tooltip";
import CloudUploadIcon from "@material-ui/icons/CloudDownload";
import { DECORATING_TECHNIQUES } from "shared/constants/decorating-techniques";
import { USD_TO_CAD_CONVERSION_RATE } from "constants/currency";
import {
  AccordionSummary,
  AccordionDetails,
  useStyles,
} from "styles/pages/product-detail/accordion";
import {
  ProductSizes,
  SizeBox,
  SizeName,
  Price,
  PricesUnit,
} from "styles/pages/product-detail/size&price";

const getTitleFromDisplayName = (displayName) => {
  return _.find(DECORATING_TECHNIQUES, (v) => v?.DisplayName === displayName)
    ?.FullName;
};

const SizePrice = ({ data }) => {
  const currency = useSelector((state) => state?.currency?.value);
  const classes = useStyles();
  const sizes = data?.Sizes || [];

  const printType = data?.Product?.PrintType;
  const isAOP = printType === "AOP";

  return (
    <Accordion className={classes.accordion} defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.heading}>
          Available sizes & pricing
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ProductSizes>
          {sizes?.map((size, index) => {
            if (size?.visible === false) return null;

            const coloredPrice =
              Number(size.ColoredPrice || 0) +
              Number(size[`${data?.Product?.PrintType}Price`] || 0);

            const whitePrice =
              Number(size.WhitePrice || 0) +
              Number(size[`${data?.Product?.PrintType}Price`] || 0);

            return (
              <div key={`size${index}`} style={{ display: "grid" }}>
                <SizeBox
                  style={{
                    gridTemplateColumns: isAOP
                      ? "2fr 1fr 1fr 1fr"
                      : "2fr 1fr 1fr",
                  }}
                >
                  <SizeName>Size</SizeName>
                  <Price>Colored</Price>
                  <Price>White</Price>
                  {isAOP && <Price>File</Price>}
                </SizeBox>
                <SizeBox
                  style={{
                    marginBottom: 20,
                    gridTemplateColumns: isAOP
                      ? "2fr 1fr 1fr 1fr"
                      : "2fr 1fr 1fr",
                  }}
                >
                  <Tooltip
                    title={`${getTitleFromDisplayName(
                      data?.Product?.PrintType
                    )}`}
                    placement="top"
                    arrow={true}
                  >
                    <SizeName>
                      {size.Name} with {data?.Product?.PrintType}
                    </SizeName>
                  </Tooltip>

                  <Price>
                    $
                    {currency === "USD"
                      ? coloredPrice.toFixed(2)
                      : (
                          Number(coloredPrice) * USD_TO_CAD_CONVERSION_RATE
                        ).toFixed(2)}
                  </Price>
                  <Price>
                    $
                    {currency === "CAD"
                      ? whitePrice.toFixed(2) * USD_TO_CAD_CONVERSION_RATE
                      : Number(whitePrice).toFixed(2)}
                  </Price>

                  {isAOP && (
                    <Price>
                      {size?.templateUrl ? (
                        <CloudUploadIcon
                          style={{ color: "#2280c1", cursor: "pointer" }}
                          onClick={() => window.open(size?.templateUrl)}
                        />
                      ) : (
                        "NA"
                      )}
                    </Price>
                  )}

                  {data?.RemainingDecorativeTechniques?.map((d) => {
                    const coloredPrice1 =
                      Number(size.ColoredPrice || 0) +
                      Number(size[`${d?.DisplayName}Price`] || 0);

                    const whitePrice1 =
                      Number(size.WhitePrice || 0) +
                      Number(size[`${d?.DisplayName}Price`] || 0);

                    return (
                      <React.Fragment key={d?.DisplayName}>
                        <Tooltip
                          title={`${getTitleFromDisplayName(d?.DisplayName)}`}
                          placement="top"
                          arrow={true}
                        >
                          <SizeName>
                            {size.Name} with {d?.DisplayName}
                          </SizeName>
                        </Tooltip>

                        <Price>
                          $
                          {currency === "USD"
                            ? coloredPrice1
                            : (
                                Number(coloredPrice1) *
                                USD_TO_CAD_CONVERSION_RATE
                              ).toFixed(2)}
                        </Price>
                        <Price>
                          $
                          {currency === "USD"
                            ? whitePrice1
                            : (
                                Number(whitePrice1) * USD_TO_CAD_CONVERSION_RATE
                              ).toFixed(2)}
                        </Price>
                      </React.Fragment>
                    );
                  })}
                </SizeBox>
              </div>
            );
          })}
        </ProductSizes>
        <PricesUnit>*Prices shown in {currency}</PricesUnit>
      </AccordionDetails>
    </Accordion>
  );
};

export default SizePrice;
