import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "components/Pagination";
import ArtworksLibraryItem from "components/ArtWork/ArtworksLibraryItem";
import {
  allCustomGraphicsUsers,
  getAllCustomGraphics,
  getCustomGraphicsTags,
} from "actions/designer";
import {
  Container,
  NoDatafound,
  PaginationWrapper,
} from "styles/components/ArtWork/custom-graphics";
import { ArtworksLibrary } from "styles/components/AIArtwork/browseLibrary";

const take = 15;

const CustomGraphics = ({ onDrop, toggle, printType }) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.profile?.token);
  const [graphicDesigners, setGraphicDesigners] = useState();
  const [selectedGraphicDesigner, setSelectedGraphicDesigner] = useState();
  const [customGraphics, setCustomGraphics] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [tags, setTags] = useState();
  const [tag, setTag] = useState();

  const fetchTags = async () => {
    const data = await dispatch(
      getCustomGraphicsTags({ user: selectedGraphicDesigner })
    );
    setTags(data);
  };

  const getGraphicDesigners = async () => {
    const data = await dispatch(allCustomGraphicsUsers());
    setGraphicDesigners(data?.filter((d) => d?.name !== "Great_Dane_Art"));
    setSelectedGraphicDesigner(data[0]?.name);
  };

  useEffect(() => {
    if (!graphicDesigners) getGraphicDesigners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphicDesigners]);

  const getCustomGraphics = async (skip = 0) => {
    const data = await dispatch(
      getAllCustomGraphics(
        {
          user: selectedGraphicDesigner,
          searchString: tag,
          take,
          skip,
          printType,
        },
        token
      )
    );

    setCustomGraphics({
      ...data,
      artFiles: data?.artFiles?.map((d) => ({
        ...d,
        public_id: d?._id,
        secure_url: d?.Thumbnail_Url,
        asset_id: d?.Art_SKU,
        verified: true,
        FileName: `${d?.Filename} ($ +${d["Shirtly_Amount(USD)"]})`,
      })),
    });
  };

  useEffect(() => {
    if (selectedGraphicDesigner) getCustomGraphics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGraphicDesigner]);

  useEffect(() => {
    if (selectedGraphicDesigner && !tags) fetchTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGraphicDesigner, tags]);

  useEffect(() => {
    if (selectedGraphicDesigner) getCustomGraphics((currentPage - 1) * take);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (selectedGraphicDesigner) {
      if (currentPage === 1) {
        getCustomGraphics();
      } else {
        setCurrentPage(1);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  return (
    <Container>
      {!!customGraphics?.artFiles?.length ? (
        <>
          {!!customGraphics?.artFileCounts && (
            <PaginationWrapper>
              <Pagination
                totalCount={customGraphics?.artFileCounts}
                take={take}
                page={currentPage}
                onChange={(e, v) => setCurrentPage(v)}
                size="small"
              />
            </PaginationWrapper>
          )}
          <ArtworksLibrary>
            <div>
              <ArtworksLibraryItem
                verify={false}
                libraryFiles={customGraphics?.artFiles}
                onSelect={(artwork) => {
                  onDrop(artwork);
                  if (toggle) {
                    toggle();
                  }
                }}
                viewOnly={false}
              />
            </div>
          </ArtworksLibrary>
        </>
      ) : (
        <NoDatafound>
          There is no artwork linked to {printType} PrintType
        </NoDatafound>
      )}
    </Container>
  );
};

export default CustomGraphics;
