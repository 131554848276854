import styled from "styled-components";

const Container = styled.div``;

const Nav = styled.nav`
  height: 83px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  position: sticky;
  top: 0;
  z-index: 999;
  @media screen and (max-width: 1100px) {
    height: 68px;
  }
`;

const NavbarContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  @media (max-width: 1100px) {
    grid-template-columns: 1fr auto 50px;
  }
`;
const NavLogo = styled.div`
  width: 100%;
  max-width: 150px;
  margin: 0 20px;
  display: flex;
  align-items: center;
  img {
    width: 80%;
    /* height: 80%; */
  }
  @media (max-width: 1100px) {
    max-width: 126px;
  }
`;

const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 1100px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-73%, 80%);
    cursor: pointer;
    svg {
      color: #ffffff;
    }
  }
`;
const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  cursor: pointer;
  padding: 0;
  @media screen and (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 53px;
    left: ${({ click }) => (click ? 0 : "-100%")};
    opacity: 1;
    transition: all 0.5s ease;
    background: #237aab;
    padding: 15px;
  }
`;

const NavLink = styled.a`
  display: flex;
  padding: 6px;
  margin: 0 6px;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  transition: all ease-out 0.25s;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 13px;

  @media screen and (max-width: 1100px) {
    text-align: center;
    padding: 15px;
    width: 100%;
    display: table;

    &:hover {
      color: #4b59f7;
      transition: all 0.3s ease;
    }
  }
`;
const NavItem = styled.li`
  display: flex;
  height: 100%;
  align-items: center;

  &:hover {
    ${NavLink} {
      color: #1a5c80;
    }
  }
  @media screen and (max-width: 1100px) {
    width: 100%;
    height: auto;
  }
`;
const LeftMenu = styled.div`
  display: flex;
  height: 100%;
  min-width: 193px;
  @media (max-width: 550px) {
    min-width: auto;
  }
`;
const RightMenu = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 224px;
  margin-right: 20px;
  Button {
    border-radius: 6px;
    margin-left: 10px;
    padding: 12px 26px;
    font-family: "Inter500";
    text-transform: capitalize;
    box-shadow: none;
    &:first-child,
    &:first-child:hover {
      background-color: #fff;
      color: #3490f4;
      box-shadow: none;
    }
    &:nth-child(2),
    &:nth-child(2):hover {
      background: #0675b3;
      color: #fff;
      box-shadow: none;
    }
  }
  @media screen and (max-width: 1100px) {
    margin: 0;
    Button {
      padding: 6px 16px;
    }
  }
  @media (max-width: 550px) {
    min-width: auto;
  }
`;

export {
  Container,
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLink,
  RightMenu,
  LeftMenu,
};
