import _ from "lodash";
import {
  getSKUFromProductId,
  getPrintTypeFromProductId,
  getDesignerSettingsFromProductId,
  productHasBackDesignerSettings,
  getAdditionalTechniquesFromProductId,
  isSub,
} from "selectors/products";
import moment from "moment";
import { json2xmlPrintAreas } from "utils/jsonToXmlParserPrintAreas";

const regExpr = /[^a-zA-Z0-9- ]/g;

const getPrintProviderName = (orderPrintProviders, order) => {
  const { printProviders, index } = orderPrintProviders;
  const selectedPP = printProviders[index];
  const shippingDestination =
    order?.CountryString !== "US" && order?.CountryString !== "CA"
      ? "Intl"
      : order?.CountryString;

  const shippingCostData = _.find(
    selectedPP?.ShippingCosts,
    (c) => c?.ShippingDestination === shippingDestination
  );

  return shippingCostData?.Title;
};

const json2xml = (
  order,
  products,
  email,
  vatIncluded,
  iossNumber,
  orderPrintProviders
) => {
  let items = "";
  let title = "";

  for (let i = 0; i < order?.OrderLines[0]?.OrderLineItems.length; i++) {
    const orderLineItem = order?.OrderLines[0]?.OrderLineItems[i];
    const salesValueInEuro = (
      (orderLineItem?.FulfillmentCost +
        (orderLineItem?.FulfillmentCost * 40) / 100) *
      1.18
    ).toFixed(2);
    const printAreas = orderLineItem?.printAreas;

    if (i === 0) {
      const printProvider = order?.PrintProvider;
      const selectedProduct = products?.find(
        (p) => p?.Product.ID === orderLineItem?.ProductID
      );
      const printProviders = selectedProduct?.PrintProviders;

      const shippingCosts = printProviders?.find(
        (s) => s?.ProviderName === printProvider
      )?.ShippingCosts;

      title = shippingCosts?.find(
        (p) => p?.ShippingDestination === order?.CountryString
      )?.Title;

      if (!title) {
        title = shippingCosts?.find(
          (p) => p?.ShippingDestination === "Intl"
        )?.Title;
      }
    }

    if (printAreas?.length > 0) {
      items += json2xmlPrintAreas(
        order,
        products,
        orderLineItem,
        vatIncluded,
        iossNumber
      );
      continue;
    }

    const sku = getSKUFromProductId(products, orderLineItem?.ProductID);
    const printType =
      orderLineItem?.PrintType ||
      getPrintTypeFromProductId(products, orderLineItem?.ProductID);
    const isOrderLineSub = isSub(products, orderLineItem?.ProductID);
    const productName = orderLineItem?.Product;

    let designerSettings = getDesignerSettingsFromProductId(
      products,
      orderLineItem?.ProductID
    );

    if (printType !== "DTG") {
      designerSettings =
        getAdditionalTechniquesFromProductId(
          products,
          orderLineItem?.ProductID,
          false,
          printType
        ) || designerSettings;
    }

    let backDesignerSettings = productHasBackDesignerSettings(
      products,
      orderLineItem?.ProductID
    );

    if (printType !== "DTG") {
      backDesignerSettings =
        getAdditionalTechniquesFromProductId(
          products,
          orderLineItem?.ProductID,
          true,
          printType
        ) || backDesignerSettings;
    }

    const artWidth = parseFloat(
      orderLineItem?.ArtWorkCoordinates?.width * designerSettings?.multiplier
    );
    const artBackWidth = parseFloat(
      orderLineItem?.BackArtWorkCoordinates?.width *
        backDesignerSettings?.multiplier
    );

    const artHeight = parseFloat(
      orderLineItem?.ArtWorkCoordinates?.height * designerSettings?.multiplier
    );

    const artBackHeight = parseFloat(
      orderLineItem?.BackArtWorkCoordinates?.height *
        backDesignerSettings?.multiplier
    );

    const canvasCenterPoint = parseFloat(designerSettings?.width) / 2;
    const canvasBackCenterPoint = parseFloat(backDesignerSettings?.width) / 2;

    const x = parseFloat(orderLineItem?.ArtWorkCoordinates?.x);
    const xBack = parseFloat(orderLineItem?.BackArtWorkCoordinates?.x);

    const imageCenter =
      parseFloat(orderLineItem?.ArtWorkCoordinates?.width) / 2;
    const backImageCenter =
      parseFloat(orderLineItem?.BackArtWorkCoordinates?.width) / 2;

    let xPos;

    if (x > canvasCenterPoint) {
      xPos =
        canvasCenterPoint -
        parseFloat(orderLineItem?.ArtWorkCoordinates?.width) +
        imageCenter;
    } else if (x < canvasCenterPoint) {
      const newValue = x + imageCenter + 1;
      xPos = newValue === canvasCenterPoint ? 0 : newValue - canvasCenterPoint;
      xPos = xPos === 1 ? 0 : xPos;
    }

    let backXPos;
    if (xBack > canvasBackCenterPoint) {
      backXPos =
        canvasBackCenterPoint -
        parseFloat(orderLineItem?.BackArtWorkCoordinates?.width) +
        backImageCenter;
    } else if (xBack < canvasBackCenterPoint) {
      const newValue = xBack + backImageCenter + 1;
      backXPos =
        newValue === canvasBackCenterPoint
          ? 0
          : newValue - canvasBackCenterPoint;
      backXPos = backXPos === 1 ? 0 : backXPos;
    }

    var yPos = parseInt(orderLineItem?.ArtWorkCoordinates?.y);
    var backYPos = parseInt(orderLineItem?.BackArtWorkCoordinates?.y);

    let artwork = orderLineItem?.Artwork?.split("/upload/");
    if (artwork) {
      artwork = `${artwork[0]}/upload/x_${Math.round(
        orderLineItem?.ArtWorkCoordinates?.x
      )},y_${Math.round(orderLineItem?.ArtWorkCoordinates?.y)}/${
        artwork[1]
      }?${moment().unix()}_front`;
    }

    let backArtwork = orderLineItem?.BackArtwork?.split("/upload/");
    if (backArtwork) {
      backArtwork = `${backArtwork[0]}/upload/x_${Math.round(
        orderLineItem?.BackArtWorkCoordinates?.x
      )},y_${Math.round(orderLineItem?.BackArtWorkCoordinates?.y)}/${
        backArtwork[1]
      }?${moment().unix()}_back`;
    }

    const vat = vatIncluded
      ? `(VALUE = €${salesValueInEuro} / QTY = ${orderLineItem.OliQuantity}${
          iossNumber ? ` / IOSS = ${iossNumber}` : ""
        })`
      : "";

    items += `<Item>
    <SKU>${sku.replace(regExpr, "")}</SKU>
    <Descrip>${
      orderLineItem.DtgPlus
        ? `[${printType}] ${vat} ${productName.replace(regExpr, "")}`
        : orderLineItem.Plus
        ? `[TUFF PRINT TRANSFER][${orderLineItem?.Plus?.DtgPlusProcess?.substr(
            0,
            2
          )?.toUpperCase()}/${orderLineItem?.Plus?.DtgPlusProcessColor}/${
            orderLineItem?.dtgColorName?.SwatchCode
          }] ${vat} ${productName.replace(regExpr, "")}`
        : `[${printType}] ${vat} ${productName.replace(regExpr, "")}`
    } </Descrip>
    <Color>${orderLineItem.Color}</Color>
    <Qty>${orderLineItem.OliQuantity}</Qty>
    <Size>${orderLineItem.Size}</Size>
    <Type>${printType}</Type>
    <Style>${sku.split("-")[0]?.replace(regExpr, "")}</Style>
    <ProcessMode>Piece</ProcessMode>
    ${
      orderLineItem?.ArtworkId
        ? `
        <Design>
          <Location>FRONT</Location>
          <Thumb>${orderLineItem.MockupSrc}</Thumb>
          <SourceFile>${
            isOrderLineSub
              ? artwork.replace(".jpg", ".png")
              : artwork.replace(".jpg", ".png")
          }</SourceFile>
          <Width>${Math.round(artWidth)}</Width>
          <Height>${Math.round(artHeight)}</Height>
          <LOffset>${Math.round(xPos * designerSettings.multiplier)}</LOffset>
          <TOffset>${Math.round(yPos * designerSettings.multiplier)}</TOffset>
          <WhiteUnderbaseRequired>false</WhiteUnderbaseRequired>
        </Design>`
        : ""
    }
    ${
      orderLineItem?.aopVectorFile
        ? `
        <Design>
          <Location>FRONT</Location>
          <Thumb>${orderLineItem.MarketingImagePath}</Thumb>
          <SourceFile>${orderLineItem.MarketingImagePath}</SourceFile>
          <Width>0</Width>
          <Height>0</Height>
          <LOffset>0</LOffset>
          <TOffset>0</TOffset>
          <WhiteUnderbaseRequired>false</WhiteUnderbaseRequired>
        </Design>`
        : ""
    }
    ${
      orderLineItem?.BackArtworkId
        ? `
        <Design>
          <Location>BACK</Location>
          <Thumb>${orderLineItem.MockupBackSrc}</Thumb>
          <SourceFile>${
            isOrderLineSub
              ? backArtwork.replace(".jpg", ".png")
              : backArtwork.replace(".jpg", ".png")
          }</SourceFile>
          <Width>${Math.round(artBackWidth)}</Width>
          <Height>${Math.round(artBackHeight)}</Height>
          <LOffset>${Math.round(
            backXPos * backDesignerSettings.multiplier
          )}</LOffset>
          <TOffset>${Math.round(
            backYPos * backDesignerSettings.multiplier
          )}</TOffset>
          <WhiteUnderbaseRequired>false</WhiteUnderbaseRequired>
        </Design>`
        : ""
    }
    </Item>`;
  }

  // title =
  //   order?.PrintProvider === 'US'
  //     ? order?.CountryString === 'US'
  //       ? 'USPS First Class Mail'
  //       : 'USPS First Class Mail'
  //     : order?.CountryString === 'CA'
  //     ? 'Expedite Parcel'
  //     : order?.CountryString === 'US'
  //     ? 'Tracked Packet - USA'
  //     : 'Tracked Packet - International';

  title =
    orderPrintProviders?.printProviders?.length > 1
      ? getPrintProviderName(orderPrintProviders, order)
      : order?.PrintProvider === "US"
      ? order?.CountryString === "US"
        ? "USPS First Class Mail"
        : "USPS First Class Mail"
      : order?.CountryString === "CA"
      ? "[Canada Post] Expedite Parcel"
      : order?.CountryString === "US"
      ? "[Canada Post] Tracked Packet - USA"
      : "[Canada Post] Tracked Packet - International";

  return `<AddXml xmlns="http://tempuri.org/">
  <OrderString>
      <Request>
          <CustID>1</CustID>
          <PO>${order?.ReceiptID}</PO>
          <OrderDate>${order.DateCreated}</OrderDate>
          <Source>Shirtly</Source>
          <Store>Shirtly</Store>
          <StoreID>1</StoreID>
          <ShipTo>
              <FirstName>${order?.BuyerName?.replace(regExpr, "")}</FirstName>
              <LastName></LastName>
              <Company></Company>
              <Adrx1>${order?.AddressLineOne?.replace(regExpr, "")}</Adrx1>
              <Adrx2>${order?.AddressLineTwo?.replace(regExpr, "")}</Adrx2>
              <City>${order?.City?.replace(regExpr, "")}</City>
              ${
                order?.militaryAddress
                  ? `<State>${
                      order?.militaryAddress
                    } ${order?.StateProvince?.replace(regExpr, "")}</State>`
                  : `<State>${order?.StateProvince?.replace(
                      regExpr,
                      ""
                    )}</State>`
              }              
              <Zip>${order?.ZipPostal?.toString()?.replace(regExpr, "")}</Zip>
              <Country>${order?.CountryString?.replace(regExpr, "")}</Country>
              <Email>${order?.MasterUserEmail}</Email>
          </ShipTo>
          ${
            order?.PrintProvider === "CA"
              ? `<ShipFrom>
                <FirstName>Big Oven Tees</FirstName>
                <LastName></LastName>
                <Company>Big Oven Tees</Company>
                <Adrx1>122 Middleton St.</Adrx1>
                <Adrx2></Adrx2>
                <City>Brantford</City>
                <State>ON</State>
                <Zip>N3S7V7</Zip>
                <Country>CA</Country>
                <Email>info@bigoventees.com</Email>
              </ShipFrom>`
              : `<ShipFrom>
              <FirstName>Big Oven Tees</FirstName>
              <LastName></LastName>
              <Company>Big Oven Tees USA</Company>
              <Adrx1>8742 Buffalo Avenue</Adrx1>
              <Adrx2></Adrx2>
              <City>Niagara Falls</City>
              <State>NY</State>
              <Zip>14304</Zip>
              <Country>US</Country>
              <Email>info@bigoventees.com</Email>
            </ShipFrom>`
          }
          <ShipMethod>${
            order?.PackingMode === "eco" ? "(Eco-Bag) " : ""
          }${title?.replace(regExpr, "")}</ShipMethod>
          <ProductionPriority>Normal</ProductionPriority>
          <Notes>${order?.message}</Notes>
          ${items}
          
      </Request>
  </OrderString>
</AddXml>`;
};

export { json2xml };
