import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { showLoader, hideLoader } from "actions/loader";
import TextField from "components/TextField";
import Button from "components/Button";
import { generateLogoImage } from "actions/aiDesignStudio";
import COLORS from "shared/constants/colors";
import {
  Container,
  Header,
  Body,
  Title,
  SubTitle,
  Logo,
  Content,
  Actions,
} from "styles/components/ArtWork/logo-generation";

const containerStyle = {
  backgroundColor: COLORS.DARK_BLUE,
  color: COLORS.WHITE,
  fontWeight: "normal",
  boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.05)",
  textTransform: "none",
  borderRadius: "3px",
  fontSize: "16px",
};

const onloadImageProcess = (src) =>
  new Promise((resolve, reject) => {
    let img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });

const LogoGeneration = ({ onDrop }) => {
  const dispatch = useDispatch();
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const token = useSelector((state) => state?.auth?.profile?.token);
  const [value, setValue] = useState("");
  const [response, setResponse] = useState();

  const save = async (url) => {
    const API_ENDPOINT =
      "https://api.cloudinary.com/v1_1/big-oven-tees-inc/upload";

    dispatch(showLoader());
    const fileData = new FormData();
    fileData.append("file", response?.data[0]?.url);
    fileData.append("upload_preset", "tnedst8q");
    fileData.append("folder", `Shirtly/${emailid}/AIArtFiles`);

    const data = await fetch(API_ENDPOINT, {
      method: "post",
      body: fileData,
    });
    const file = await data?.json();
    let secureUrl = file.secure_url;
    await onloadImageProcess(secureUrl);

    dispatch(hideLoader());

    return file;
  };

  const disabled = !value?.trim();

  return (
    <Container>
      <Title>AI Generated Logos</Title>
      <SubTitle>Generate a professional business logo using AI</SubTitle>

      <Header>
        <TextField
          size="small"
          label="Company Name"
          placeholder="Enter Text"
          value={value}
          onChange={(v) => setValue(v)}
        />

        <Button
          disabled={disabled}
          containerStyle={{
            ...containerStyle,
            opacity: disabled ? 0.4 : 1,
            width: "40px",
          }}
          title="Go"
          onClick={async () => {
            const v = await dispatch(
              generateLogoImage(
                {
                  message: `Generate a single variant of professional logo without shadow based on description : ${value}`,
                  size: "1024x1024",
                },
                token
              )
            );

            setResponse(v);
          }}
        />
      </Header>

      <Body>
        {response?.data?.length > 0 && (
          <Content>
            <Logo src={response?.data[0]?.url} alt="ai" />
            <Actions>
              <Button
                containerStyle={containerStyle}
                title="Save"
                onClick={save}
              />
              <Button
                containerStyle={containerStyle}
                title="Save & Use"
                onClick={async () => {
                  const file = await save();
                  onDrop(file);
                }}
              />
            </Actions>
          </Content>
        )}
      </Body>
    </Container>
  );
};

export default LogoGeneration;
