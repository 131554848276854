import React from "react";
import _ from "lodash";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import { PaginationContainer } from "styles/components/Pagination";

const useStyles = makeStyles(() => ({
  root: {
    margin: "0 auto",
  },
}));

const CustomPagination = ({
  totalCount,
  take,
  page,
  onChange,
  showFirstButton = true,
  showLastButton = true,
  hideNextButton = false,
  hidePrevButton = false,
  size = "large",
}) => {
  const classes = useStyles();

  if (!totalCount || totalCount < take) {
    return null;
  }

  return (
    <PaginationContainer>
      <div className={classes.root}>
        <Pagination
          style={{ margin: "0 auto", width: "fit-content" }}
          count={Math.round(
            totalCount / take +
              (totalCount % take > 0 && totalCount % take < 5 ? 1 : 0)
          )}
          size={size}
          color="primary"
          showFirstButton={showFirstButton}
          showLastButton={showLastButton}
          hideNextButton={hideNextButton}
          hidePrevButton={hidePrevButton}
          page={page}
          onChange={(e, v) => _.isFunction(onChange) && onChange(e, v)}
        />
      </div>
    </PaginationContainer>
  );
};

export default CustomPagination;
