import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "actions/loader";
import Drawer from "@material-ui/core/Drawer";
import EditTextDrawer from "components/ArtWork/EditTextDrawer";
import TextField from "components/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import CropFreeIcon from "@material-ui/icons/CropFree";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import VerticalAlignCenterIcon from "@material-ui/icons/VerticalAlignCenter";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import VerticalAlignTopIcon from "@material-ui/icons/VerticalAlignTop";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";
import KeyboardTabIcon from "@material-ui/icons/KeyboardTab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import Chip from "@material-ui/core/Chip";
import Slider from "@material-ui/core/Slider";
import Button from "components/Button";
import COLORS from "shared/constants/colors";
import {
  LayersContainer,
  Header,
  CloseIconStyle,
  LayersWrapper,
  Layer,
  LayerActions,
  TextLayer,
  LayerCenter,
  AlignIcons,
  UpperLayer,
  Rotate,
} from "styles/components/ArtWork/browseLibrary";

const LayersDrawer = ({
  open,
  toggle,
  canvas,
  onApply,
  printArea,
  plus,
  orderLine,
  setOrderLine,
}) => {
  const dispatch = useDispatch();
  const [canvasJSON, setCanvasJSON] = useState(canvas?.toJSON());
  const [editTextDrawer, setEditTextDrawer] = useState(false);
  const [activeObjectIndex, setActiveObjectIndex] = useState(0);
  const printType = orderLine?.PrintType;

  useEffect(() => {
    if (!!canvas?.getObjects()?.length)
      canvas.setActiveObject(canvas?.getObjects()[activeObjectIndex]);
    canvas.requestRenderAll();
  }, [activeObjectIndex]);

  return (
    <>
      <LayersContainer style={{ width: "100%" }}>
        <Header>
          <h3>Layers ({printArea?.printAreaName})</h3>
        </Header>
        <LayersWrapper>
          {canvasJSON?.objects?.map((o, i) => {
            const widthInPixel =
              (Number(o?.width) * o?.scaleX) /
              printArea?.designerSettings?.multiplier;
            const v = printArea?.designerSettings?.width;
            const widthInInches =
              o?.width < 1
                ? 255 / printArea?.designerSettings?.multiplier
                : widthInPixel > v
                ? v
                : widthInPixel;

            const heightInPixel =
              (Number(o?.height) * o?.scaleY) /
              printArea?.designerSettings?.multiplier;
            const vh = printArea?.designerSettings?.height;
            const heightInInches =
              o?.height < 1
                ? 255 / printArea?.designerSettings?.multiplier
                : heightInPixel > vh
                ? vh
                : heightInPixel;

            const widthPercentage = parseInt(
              ((+o?.width / 255) * 100) / +widthInInches
            );

            const isCustomGraphics = o?.src?.includes("/CustomGraphics/");

            return (
              <UpperLayer
                key={`objectsLayer${i}`}
                style={{
                  borderLeftColor:
                    activeObjectIndex === i ? "#000" : "transparent",
                }}
              >
                <Layer
                  onClick={() => {
                    setActiveObjectIndex(i);
                  }}
                >
                  {o?.type === "image" && (
                    <img key={i} src={o?.src} width="60" alt="" />
                  )}

                  {o?.type === "i-text" && (
                    <TextLayer>
                      <TextFieldsIcon />
                    </TextLayer>
                  )}
                  <LayerCenter>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Chip label={o?.type === "image" ? "Image" : "Text"} />
                      {o?.type === "i-text" && (
                        <div
                          style={{
                            marginLeft: 10,
                            fontWeight: o?.fontWeight,
                            fontFamily: o?.fontFamily,
                            fontStyle: o?.fontStyle,
                            fontSize: 16,
                            textDecoration: `${
                              o?.underline ? "underline" : ""
                            } ${o?.overline ? "overline" : ""} ${
                              o?.linethrough ? "line-through" : ""
                            }`,
                          }}
                        >
                          {o?.text}
                        </div>
                      )}
                    </div>

                    <div style={{ marginTop: 5, display: "none" }}>
                      <span style={{ marginRight: 10 }}>
                        <b>
                          Print Quality:&nbsp;
                          {o?.type === "i-text" || isCustomGraphics ? (
                            <span style={{ color: "green" }}>Good</span>
                          ) : (
                            <span
                              style={{
                                color:
                                  widthPercentage < 20
                                    ? "red"
                                    : widthPercentage > 20 &&
                                      widthPercentage < 50
                                    ? "#f36c62"
                                    : widthPercentage > 50 &&
                                      widthPercentage < 80
                                    ? "orange"
                                    : "green",
                              }}
                            >
                              {widthPercentage < 20
                                ? "Very Poor"
                                : widthPercentage > 20 && widthPercentage < 50
                                ? "Poor"
                                : widthPercentage > 50 && widthPercentage < 80
                                ? "Average"
                                : "Good"}
                            </span>
                          )}
                        </b>
                      </span>
                    </div>

                    <div style={{ marginTop: 5 }}>
                      <span style={{ marginRight: 10 }}>
                        <b>Width:</b>&nbsp;
                        {widthInInches.toFixed(2)}"
                      </span>
                      <span>
                        <b>Height:</b>&nbsp;
                        {heightInInches.toFixed(2)}"
                      </span>
                    </div>
                  </LayerCenter>
                  <LayerActions>
                    {o?.type === "i-text" && (
                      <Tooltip title="Edit">
                        <EditIcon
                          style={{ color: "#000" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setEditTextDrawer({ ...o, index: i });
                          }}
                        />
                      </Tooltip>
                    )}
                    {printType !== "EMB" && o?.type !== "image" && (
                      <Tooltip title="Clone">
                        <FileCopyIcon
                          style={{ color: "#000" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            const data = {
                              ...canvasJSON,
                              objects: [
                                ...canvasJSON.objects,
                                { ...o, top: o?.top + 10, left: o?.left + 10 },
                              ],
                            };

                            setCanvasJSON(data);
                            onApply(data);
                            setTimeout(() => {
                              setActiveObjectIndex(
                                canvas?.toJSON()?.objects?.length - 1
                              );
                            }, 100);
                          }}
                        />
                      </Tooltip>
                    )}

                    <Tooltip title="Delete">
                      <DeleteIcon
                        style={{ color: "red" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();

                          const data = {
                            ...canvasJSON,
                            objects: canvasJSON.objects.filter(
                              (item, j) => j !== i
                            ),
                          };

                          setCanvasJSON(data);
                          onApply(data);
                          setActiveObjectIndex(0);

                          if (o?.type === "image") {
                            const printAreas = orderLine?.printAreas?.map(
                              (p) => {
                                if (!p?.selected) return p;

                                const customGraphicsdData =
                                  p?.customGraphicsdData;

                                return {
                                  ...p,
                                  customGraphicsdData:
                                    customGraphicsdData?.filter(
                                      (c) =>
                                        !o?.src?.includes(
                                          c?.fileName?.split(".")[0]
                                        )
                                    ),
                                };
                              }
                            );
                            setOrderLine({
                              ...orderLine,
                              printAreas,
                            });
                          }
                        }}
                      />
                    </Tooltip>
                  </LayerActions>
                </Layer>
                {activeObjectIndex === i && (
                  <div style={{ marginTop: 5 }}>
                    <h4 style={{ margin: "0 0 5px 0" }}>Align</h4>
                    <AlignIcons>
                      <Tooltip title="Horizontal Align">
                        <FormatAlignCenterIcon
                          onClick={() => {
                            const obj = canvas.getActiveObject();
                            obj.centerH();
                            const json = canvas?.toJSON();
                            setCanvasJSON(json);
                            canvas.fire("object:modified", { target: obj });
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Vertical Align">
                        <VerticalAlignCenterIcon
                          onClick={() => {
                            const obj = canvas.getActiveObject();
                            obj.centerV();
                            const json = canvas?.toJSON();
                            setCanvasJSON(json);
                            canvas.fire("object:modified", { target: obj });
                          }}
                        />
                      </Tooltip>

                      <Tooltip title="Align Top">
                        <VerticalAlignTopIcon
                          onClick={() => {
                            const obj = canvas.getActiveObject();
                            obj.set({ top: 0 });
                            canvas.requestRenderAll();
                            const json = canvas?.toJSON();
                            setCanvasJSON(json);
                            canvas.fire("object:modified", { target: obj });
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Align Bottom">
                        <VerticalAlignBottomIcon
                          onClick={() => {
                            const activeObject = canvas.getActiveObject();
                            activeObject.set({
                              top:
                                canvas?.height -
                                activeObject.height * activeObject.scaleY,
                            });
                            canvas.requestRenderAll();
                            const json = canvas?.toJSON();
                            setCanvasJSON(json);
                            canvas.fire("object:modified", {
                              target: activeObject,
                            });
                          }}
                        />
                      </Tooltip>

                      <Tooltip title="Align Right">
                        <KeyboardTabIcon
                          onClick={() => {
                            const activeObject = canvas.getActiveObject();
                            activeObject.set({
                              left:
                                canvas.width -
                                activeObject.width * activeObject.scaleX,
                            });
                            canvas.requestRenderAll();
                            const json = canvas?.toJSON();
                            setCanvasJSON(json);
                            canvas.fire("object:modified", {
                              target: activeObject,
                            });
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Align Left">
                        <ArrowBackIcon
                          onClick={() => {
                            const activeObject = canvas.getActiveObject();
                            activeObject.set({
                              left: 0,
                            });
                            canvas.requestRenderAll();
                            const json = canvas?.toJSON();
                            setCanvasJSON(json);
                            canvas.fire("object:modified", {
                              target: activeObject,
                            });
                          }}
                        />
                      </Tooltip>

                      <Tooltip title="Flip Vertical">
                        <SyncAltIcon
                          onClick={() => {
                            const activeObject = canvas.getActiveObject();
                            activeObject.toggle("flipX");
                            canvas.requestRenderAll();
                            const json = canvas?.toJSON();
                            setCanvasJSON(json);
                            canvas.fire("object:modified", {
                              target: activeObject,
                            });
                          }}
                        />
                      </Tooltip>

                      <Tooltip title="Flip Horizontal">
                        <ImportExportIcon
                          onClick={() => {
                            const activeObject = canvas.getActiveObject();
                            activeObject.toggle("flipY");
                            canvas.requestRenderAll();
                            const json = canvas?.toJSON();
                            setCanvasJSON(json);
                            canvas.fire("object:modified", {
                              target: activeObject,
                            });
                          }}
                        />
                      </Tooltip>
                    </AlignIcons>
                  </div>
                )}

                {o?.type === "image" && (
                  <Button
                    title="Remove Background"
                    endIcon={<CropFreeIcon />}
                    containerStyle={{
                      backgroundColor: COLORS.DARK_BLUE,
                      color: COLORS.WHITE,
                      fontWeight: "normal",
                      boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.05)",
                      textTransform: "none",
                      borderRadius: "3px",
                      lineHeight: "1.5",
                      marginTop: "20px",
                      width: "100%",
                    }}
                    onClick={async () => {
                      dispatch(showLoader());

                      const secureUrlSplit = o?.src.split("/upload/");
                      const url = `${secureUrlSplit[0]}/upload/e_background_removal/${secureUrlSplit[1]}`;

                      const image = new Image();

                      image.onload = () => {
                        dispatch(hideLoader());

                        const data = {
                          ...canvasJSON,
                          objects: canvasJSON.objects.map((item, j) => {
                            if (j !== i) {
                              return item;
                            }

                            return { ...item, src: url };
                          }),
                        };

                        setCanvasJSON(data);
                        onApply(data);
                      };

                      image.onerror = () => {
                        dispatch(showLoader());
                        image.src = `${url}?timestamp=${moment().unix()}`;
                      };

                      image.src = url;
                    }}
                  />
                )}

                {o?.type === "i-text" && (
                  <div style={{ marginTop: 15 }}>
                    <h4 style={{ margin: "0 0 5px 0" }}>Font Size</h4>
                    <Rotate>
                      <Slider
                        defaultValue={o?.fontSize}
                        min={10}
                        max={90}
                        value={o?.fontSize}
                        onChange={(e, v) => {
                          const obj = canvas.getActiveObject();
                          obj.set("fontSize", v);
                          canvas.requestRenderAll();
                          const json = canvas?.toJSON();
                          setCanvasJSON(json);
                          canvas.fire("object:modified", { target: obj });
                        }}
                      />

                      <TextField
                        label="Font Size"
                        min={10}
                        max={90}
                        type="number"
                        size="small"
                        value={o?.fontSize}
                        onChange={(fontSize) => {
                          if (+fontSize <= 90) {
                            const obj = canvas.getActiveObject();
                            obj.set("fontSize", fontSize || 10);
                            canvas.requestRenderAll();
                            const json = canvas?.toJSON();
                            setCanvasJSON(json);
                            canvas.fire("object:modified", { target: obj });
                          }
                        }}
                      />
                    </Rotate>
                  </div>
                )}

                {activeObjectIndex === i && (
                  <div style={{ marginTop: 15 }}>
                    <h4 style={{ margin: "0 0 5px 0" }}>Rotate</h4>
                    <Rotate>
                      <Slider
                        defaultValue={10}
                        min={-180}
                        max={180}
                        value={o?.angle}
                        onChange={(e, v) => {
                          const obj = canvas.getActiveObject();
                          obj.rotate(v);
                          canvas.requestRenderAll();
                          const json = canvas?.toJSON();
                          setCanvasJSON(json);
                          canvas.fire("object:modified", { target: obj });
                        }}
                      />

                      <TextField
                        label="Angle"
                        type="number"
                        size="small"
                        value={o?.angle}
                        onChange={(angle) => {
                          const obj = canvas.getActiveObject();
                          obj.rotate(Number(angle));
                          canvas.requestRenderAll();
                          const json = canvas?.toJSON();
                          setCanvasJSON(json);
                          canvas.fire("object:modified", { target: obj });
                        }}
                      />
                    </Rotate>
                  </div>
                )}
              </UpperLayer>
            );
          })}
        </LayersWrapper>
      </LayersContainer>
      {!!editTextDrawer && (
        <EditTextDrawer
          plus={plus}
          open={!!editTextDrawer}
          toggle={() => setEditTextDrawer(false)}
          onDrop={(json) => {
            const data = {
              ...canvasJSON,
              objects: canvasJSON.objects?.map((o, i) => {
                if (i === json?.index) return json;
                return o;
              }),
            };
            setCanvasJSON(data);
            setEditTextDrawer(false);
            onApply(data);
          }}
          data={editTextDrawer}
        />
      )}
    </>
  );
};

export default LayersDrawer;
